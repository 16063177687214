export const localidades = [
  {
    "nombre": "CORDOBA",
    "coordenadas": [
      {
        "lat": -31.422634,
        "lng": -64.1844204
      }
    ]
  },
  {
    "nombre": "SANTA FE",
    "coordenadas": [
      {
        "lat": -31.6340502,
        "lng": -60.6969691
      }
    ]
  },
  {
    "nombre": "ROSARIO",
    "coordenadas": [
      {
        "lat": -32.9358072,
        "lng": -60.6530284
      }
    ]
  },
  {
    "nombre": "CIUDAD AUTONOMA DE BS AS",
    "coordenadas": [
      {
        "lat": -34.5830745,
        "lng": -58.4261387
      }
    ]
  },
  {
    "nombre": "MAR DEL PLATA",
    "coordenadas": [
      {
        "lat": -37.9823635,
        "lng": -57.5449998
      }
    ]
  },
  {
    "nombre": "CORRIENTES",
    "coordenadas": [
      {
        "lat": -27.4824594,
        "lng": -58.8494998
      }
    ]
  },
  {
    "nombre": "NEUQUEN",
    "coordenadas": [
      {
        "lat": -38.959044,
        "lng": -68.105841
      }
    ]
  },
  {
    "nombre": "CIPOLLETTI",
    "coordenadas": [
      {
        "lat": -38.9394174,
        "lng": -67.99547489999999
      }
    ]
  },
  {
    "nombre": "SAN MIGUEL DE TUCUMAN",
    "coordenadas": [
      {
        "lat": -26.8206298,
        "lng": -65.1986834
      }
    ]
  },
  {
    "nombre": "RIO TERCERO",
    "coordenadas": [
      {
        "lat": -32.1723987,
        "lng": -64.11209269999999
      }
    ]
  },
  {
    "nombre": "RIO CUARTO",
    "coordenadas": [
      {
        "lat": -33.1229427,
        "lng": -64.35038089999999
      }
    ]
  },
  {
    "nombre": "HERNANDO",
    "coordenadas": [
      {
        "lat": -32.1728741,
        "lng": -64.2750263
      }
    ]
  },
  {
    "nombre": "VILLA CARLOS PAZ",
    "coordenadas": [
      {
        "lat": -31.4212323,
        "lng": -64.4916836
      }
    ]
  },
  {
    "nombre": "RAFAELA",
    "coordenadas": [
      {
        "lat": -31.2511145,
        "lng": -61.4910562
      }
    ]
  },
  {
    "nombre": "CONCORDIA",
    "coordenadas": [
      {
        "lat": -31.400787,
        "lng": -58.0211414
      }
    ]
  },
  {
    "nombre": "VALLE DE LAS LEÑAS",
    "coordenadas": [
      {
        "lat": -35.1602778,
        "lng": -70.0669444
      }
    ]
  },
  {
    "nombre": "VILLAGUAY",
    "coordenadas": [
      {
        "lat": -31.8677008,
        "lng": -59.0269219
      }
    ]
  },
  {
    "nombre": "CONCEPCION DEL URUGUAY",
    "coordenadas": [
      {
        "lat": -32.485716,
        "lng": -58.235356
      }
    ]
  },
  {
    "nombre": "SAN RAFAEL",
    "coordenadas": [
      {
        "lat": -34.6095287,
        "lng": -68.33126639999999
      }
    ]
  },
  {
    "nombre": "SAN CARLOS DE BARILOCHE",
    "coordenadas": [
      {
        "lat": -41.1360414,
        "lng": -71.3076743
      }
    ]
  },
  {
    "nombre": "MARCOS JUAREZ",
    "coordenadas": [
      {
        "lat": -32.7100765,
        "lng": -62.1092081
      }
    ]
  },
  {
    "nombre": "PUERTO MADRYN",
    "coordenadas": [
      {
        "lat": -42.7757867,
        "lng": -65.0398963
      }
    ]
  },
  {
    "nombre": "AREQUITO",
    "coordenadas": [
      {
        "lat": -33.1451481,
        "lng": -61.4688795
      }
    ]
  },
  {
    "nombre": "MENDOZA",
    "coordenadas": [
      {
        "lat": -32.8899727,
        "lng": -68.8502791
      }
    ]
  },
  {
    "nombre": "ESPERANZA",
    "coordenadas": [
      {
        "lat": -31.44785139999999,
        "lng": -60.93110160000001
      }
    ]
  },
  {
    "nombre": "BAHIA BLANCA",
    "coordenadas": [
      {
        "lat": -38.7005456,
        "lng": -62.264836
      }
    ]
  },
  {
    "nombre": "RECONQUISTA",
    "coordenadas": [
      {
        "lat": -29.1464385,
        "lng": -59.64597969999999
      }
    ]
  },
  {
    "nombre": "VERONICA",
    "coordenadas": [
      {
        "lat": -35.3873557,
        "lng": -57.33841109999999
      }
    ]
  },
  {
    "nombre": "EL CALAFATE",
    "coordenadas": [
      {
        "lat": -50.3382416,
        "lng": -72.26655579999999
      }
    ]
  },
  {
    "nombre": "RIVADAVIA",
    "coordenadas": [
      {
        "lat": -31.5103618,
        "lng": -68.5996281
      }
    ]
  },
  {
    "nombre": "CHILECITO",
    "coordenadas": [
      {
        "lat": -29.165648,
        "lng": -67.49459209999999
      }
    ]
  },
  {
    "nombre": "BALNEARIO LAS GRUTAS",
    "coordenadas": [
      {
        "lat": -40.8117904,
        "lng": -65.094284
      }
    ]
  },
  {
    "nombre": "MAIPU",
    "coordenadas": [
      {
        "lat": -32.9828848,
        "lng": -68.797673
      }
    ]
  },
  {
    "nombre": "SAN JUAN",
    "coordenadas": [
      {
        "lat": -31.5340572,
        "lng": -68.5232351
      }
    ]
  },
  {
    "nombre": "LA RIOJA",
    "coordenadas": [
      {
        "lat": -29.3942875,
        "lng": -66.8470949
      }
    ]
  },
  {
    "nombre": "CHAMICAL",
    "coordenadas": [
      {
        "lat": -30.361165,
        "lng": -66.3140926
      }
    ]
  },
  {
    "nombre": "SAN MARTIN",
    "coordenadas": [
      {
        "lat": -33.0717477,
        "lng": -68.47680869999999
      }
    ]
  },
  {
    "nombre": "GODOY CRUZ",
    "coordenadas": [
      {
        "lat": -32.927881,
        "lng": -68.8494714
      }
    ]
  },
  {
    "nombre": "LUJAN DE CUYO",
    "coordenadas": [
      {
        "lat": -32.9681509,
        "lng": -68.8759582
      }
    ]
  },
  {
    "nombre": "VILLA KRAUSE",
    "coordenadas": [
      {
        "lat": -31.5826588,
        "lng": -68.54227809999999
      }
    ]
  },
  {
    "nombre": "GENERAL ROCA",
    "coordenadas": [
      {
        "lat": -39.032803,
        "lng": -67.5892227
      }
    ]
  },
  {
    "nombre": "POCITO",
    "coordenadas": [
      {
        "lat": -31.6573191,
        "lng": -68.5798848
      }
    ]
  },
  {
    "nombre": "ZONDA",
    "coordenadas": [
      {
        "lat": -31.5208732,
        "lng": -68.818195
      }
    ]
  },
  {
    "nombre": "TUNUYAN",
    "coordenadas": [
      {
        "lat": -33.5767491,
        "lng": -69.01600239999999
      }
    ]
  },
  {
    "nombre": "VILLA ALLENDE",
    "coordenadas": [
      {
        "lat": -31.2999506,
        "lng": -64.2767439
      }
    ]
  },
  {
    "nombre": "BARRIO RAWSON",
    "coordenadas": [
      {
        "lat": -31.5427531,
        "lng": -68.5252239
      }
    ]
  },
  {
    "nombre": "ELDORADO",
    "coordenadas": [
      {
        "lat": -26.4009219,
        "lng": -54.635096
      }
    ]
  },
  {
    "nombre": "JACHAL",
    "coordenadas": [
      {
        "lat": -30.25,
        "lng": -68.75
      }
    ]
  },
  {
    "nombre": "DIFUNTA CORREA",
    "coordenadas": [
      {
        "lat": -31.734499,
        "lng": -67.9827669
      }
    ]
  },
  {
    "nombre": "TINOGASTA",
    "coordenadas": [
      {
        "lat": -28.0654464,
        "lng": -67.566187
      }
    ]
  },
  {
    "nombre": "SANTA ROSA",
    "coordenadas": [
      {
        "lat": -36.618555,
        "lng": -64.2918786
      }
    ]
  },
  {
    "nombre": "EX FORTIN LAVALLE",
    "coordenadas": [
      {
        "lat": -25.7040525,
        "lng": -60.2007245
      }
    ]
  },
  {
    "nombre": "PRESIDENCIA ROQUE SAENZ PEÑA",
    "coordenadas": [
      {
        "lat": -26.780781,
        "lng": -60.4387965
      }
    ]
  },
  {
    "nombre": "OBERA",
    "coordenadas": [
      {
        "lat": -27.4860416,
        "lng": -55.1220273
      }
    ]
  },
  {
    "nombre": "RESISTENCIA",
    "coordenadas": [
      {
        "lat": -27.4421819,
        "lng": -58.9977379
      }
    ]
  },
  {
    "nombre": "TEMPERLEY",
    "coordenadas": [
      {
        "lat": -34.774648,
        "lng": -58.395765
      }
    ]
  },
  {
    "nombre": "WILDE",
    "coordenadas": [
      {
        "lat": -34.7062393,
        "lng": -58.3404415
      }
    ]
  },
  {
    "nombre": "LANUS",
    "coordenadas": [
      {
        "lat": -34.6841313,
        "lng": -58.38639130000001
      }
    ]
  },
  {
    "nombre": "AVELLANEDA",
    "coordenadas": [
      {
        "lat": -34.6775055,
        "lng": -58.3478817
      }
    ]
  },
  {
    "nombre": "VILLA DOMINICO",
    "coordenadas": [
      {
        "lat": -34.6944893,
        "lng": -58.32189769999999
      }
    ]
  },
  {
    "nombre": "QUILMES",
    "coordenadas": [
      {
        "lat": -34.7278392,
        "lng": -58.2648528
      }
    ]
  },
  {
    "nombre": "LOMAS DE ZAMORA",
    "coordenadas": [
      {
        "lat": -34.7585923,
        "lng": -58.3975708
      }
    ]
  },
  {
    "nombre": "CASTELAR",
    "coordenadas": [
      {
        "lat": -34.6487742,
        "lng": -58.6422854
      }
    ]
  },
  {
    "nombre": "RAMOS MEJIA",
    "coordenadas": [
      {
        "lat": -34.6547697,
        "lng": -58.5856783
      }
    ]
  },
  {
    "nombre": "GREGORIO DE LAFERRERE",
    "coordenadas": [
      {
        "lat": -34.7457928,
        "lng": -58.590822
      }
    ]
  },
  {
    "nombre": "SAN JUSTO",
    "coordenadas": [
      {
        "lat": -34.6707977,
        "lng": -58.56213009999999
      }
    ]
  },
  {
    "nombre": "BOULOGNE",
    "coordenadas": [
      {
        "lat": -34.4980995,
        "lng": -58.5542695
      }
    ]
  },
  {
    "nombre": "MUNRO",
    "coordenadas": [
      {
        "lat": -34.5281105,
        "lng": -58.5177235
      }
    ]
  },
  {
    "nombre": "PABLO PODESTA",
    "coordenadas": [
      {
        "lat": -34.5726168,
        "lng": -58.61779660000001
      }
    ]
  },
  {
    "nombre": "CITY BELL",
    "coordenadas": [
      {
        "lat": -34.8741838,
        "lng": -58.0481708
      }
    ]
  },
  {
    "nombre": "MANUEL B GONNET",
    "coordenadas": [
      {
        "lat": -34.8777309,
        "lng": -58.016651
      }
    ]
  },
  {
    "nombre": "LA PLATA",
    "coordenadas": [
      {
        "lat": -34.9202153,
        "lng": -57.95575090000001
      }
    ]
  },
  {
    "nombre": "MORON",
    "coordenadas": [
      {
        "lat": -34.6494068,
        "lng": -58.6141916
      }
    ]
  },
  {
    "nombre": "HAEDO",
    "coordenadas": [
      {
        "lat": -34.6544617,
        "lng": -58.600748
      }
    ]
  },
  {
    "nombre": "CHARATA",
    "coordenadas": [
      {
        "lat": -27.7899757,
        "lng": -64.2687159
      }
    ]
  },
  {
    "nombre": "25 DE MAYO",
    "coordenadas": [
      {
        "lat": -27.3768374,
        "lng": -54.7457913
      }
    ]
  },
  {
    "nombre": "SANTO TOME",
    "coordenadas": [
      {
        "lat": -31.6727704,
        "lng": -60.7689185
      }
    ]
  },
  {
    "nombre": "MERCEDES",
    "coordenadas": [
      {
        "lat": -34.6518545,
        "lng": -59.4305555
      }
    ]
  },
  {
    "nombre": "MONTECARLO",
    "coordenadas": [
      {
        "lat": -26.5678871,
        "lng": -54.7650853
      }
    ]
  },
  {
    "nombre": "GDOR ING V VIRASORO",
    "coordenadas": [
      {
        "lat": -28.0531193,
        "lng": -56.0199152
      }
    ]
  },
  {
    "nombre": "LEANDRO N ALEM",
    "coordenadas": [
      {
        "lat": -27.4442798,
        "lng": -55.5014701
      }
    ]
  },
  {
    "nombre": "VICENTE LOPEZ",
    "coordenadas": [
      {
        "lat": -34.5218306,
        "lng": -58.4844778
      }
    ]
  },
  {
    "nombre": "SAN ISIDRO",
    "coordenadas": [
      {
        "lat": -34.4798843,
        "lng": -58.52386310000001
      }
    ]
  },
  {
    "nombre": "MORENO",
    "coordenadas": [
      {
        "lat": -34.6008227,
        "lng": -58.7569991
      }
    ]
  },
  {
    "nombre": "METAN",
    "coordenadas": [
      {
        "lat": -25.4960231,
        "lng": -64.9749319
      }
    ]
  },
  {
    "nombre": "SANTIAGO DEL ESTERO",
    "coordenadas": [
      {
        "lat": -27.7903105,
        "lng": -64.2580018
      }
    ]
  },
  {
    "nombre": "SAN ANTONIO DE LA PAZ",
    "coordenadas": [
      {
        "lat": -28.9329054,
        "lng": -65.0919991
      }
    ]
  },
  {
    "nombre": "SAN SALVADOR DE JUJUY",
    "coordenadas": [
      {
        "lat": -24.1857864,
        "lng": -65.2994767
      }
    ]
  },
  {
    "nombre": "PERICO",
    "coordenadas": [
      {
        "lat": -24.3849622,
        "lng": -65.1208524
      }
    ]
  },
  {
    "nombre": "PALPALA",
    "coordenadas": [
      {
        "lat": -24.2584127,
        "lng": -65.2084907
      }
    ]
  },
  {
    "nombre": "SALTA",
    "coordenadas": [
      {
        "lat": -24.7785037,
        "lng": -65.4110372
      }
    ]
  },
  {
    "nombre": "CONCEPCION",
    "coordenadas": [
      {
        "lat": -33.4488897,
        "lng": -70.6692655
      }
    ]
  },
  {
    "nombre": "MONTEROS",
    "coordenadas": [
      {
        "lat": -27.1715903,
        "lng": -65.49653219999999
      }
    ]
  },
  {
    "nombre": "TAFI DEL VALLE",
    "coordenadas": [
      {
        "lat": -33.5332084,
        "lng": -70.6633227
      }
    ]
  },
  {
    "nombre": "AGUILARES",
    "coordenadas": [
      {
        "lat": -28.186577,
        "lng": -63.580611
      }
    ]
  },
  {
    "nombre": "EL MOLLAR",
    "coordenadas": [
      {
        "lat": -26.9387,
        "lng": -65.70705
      }
    ]
  },
  {
    "nombre": "SIMOCA",
    "coordenadas": [
      {
        "lat": -27.2630357,
        "lng": -65.35782619999999
      }
    ]
  },
  {
    "nombre": "CAÑADA",
    "coordenadas": [
      {
        "lat": -30.24415339999999,
        "lng": -60.58206759999999
      }
    ]
  },
  {
    "nombre": "VENADO TUERTO",
    "coordenadas": [
      {
        "lat": -33.745978,
        "lng": -61.96555309999999
      }
    ]
  },
  {
    "nombre": "PEREZ",
    "coordenadas": [
      {
        "lat": -33.0010562,
        "lng": -60.7648957
      }
    ]
  },
  {
    "nombre": "CARCARAÑA",
    "coordenadas": [
      {
        "lat": -32.8577933,
        "lng": -61.1480003
      }
    ]
  },
  {
    "nombre": "EL BOLSON",
    "coordenadas": [
      {
        "lat": -41.9671324,
        "lng": -71.5363206
      }
    ]
  }
]


export const puntosDeVenta = [
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "RONDEAU 159",
    "localidad": "CORDOBA",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.422634,
    "lng": -64.1844204
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "General Manuel Belgrano 731",
    "localidad": "CORDOBA",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.4235249,
    "lng": -64.1917402
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "LUIS DE TEJEDA 4289",
    "localidad": "CORDOBA",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.3697019,
    "lng": -64.2348923
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "LUIS DE TEJEDA 3946",
    "localidad": "CORDOBA",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.3741956,
    "lng": -64.230741
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "LUIS DE TEJEDA 3946",
    "localidad": "CORDOBA",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.3741956,
    "lng": -64.230741
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "JOSE MANUEL ESTRADA 174",
    "localidad": "CORDOBA",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.4282623,
    "lng": -64.18651849999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "DR.T.ACHAVAL RODRIGUEZ 236",
    "localidad": "CORDOBA",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.4244448,
    "lng": -64.191096
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "LAS HERAS 3799",
    "localidad": "SANTA FE",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.6340502,
    "lng": -60.6969691
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "ALVEAR 144",
    "localidad": "ROSARIO",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.9358072,
    "lng": -60.6530284
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "OLIVE 1160",
    "localidad": "ROSARIO",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.912837,
    "lng": -60.67989339999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "OLIVE 1150",
    "localidad": "ROSARIO",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.9127618,
    "lng": -60.6797533
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "COSTANERA ESTE 333",
    "localidad": "SANTA FE",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.6374391,
    "lng": -60.6764307
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "PARAGUAY 4683",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5830745,
    "lng": -58.4261387
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "PARAGUAY 4101",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5875671,
    "lng": -58.42012380000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "GODOY CRUZ 2209",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5833889,
    "lng": -58.42925400000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "URIARTE 1616",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5872579,
    "lng": -58.4319782
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "JORGE LUIS BORGES 1766",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5875724,
    "lng": -58.42862519999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "FRAY JUSTO SANTA MARIA DE ORO 2695",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.577616,
    "lng": -58.4225749
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "RECONQUISTA 1021",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5958278,
    "lng": -58.3730936
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "ARMENIA 1986",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.58747049999999,
    "lng": -58.42453749999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "CARLOS PELLEGRINI 953",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5971306,
    "lng": -58.38122730000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "PARAGUAY 4597",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5839839,
    "lng": -58.4250063
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "MARCELO T DE ALVEAR 401",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5960321,
    "lng": -58.3733097
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "ORO 2188",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6036844,
    "lng": -58.3815591
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA CONGRESO 2699",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5567182,
    "lng": -58.46526670000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "IGNACIO NUNEZ 2591",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6036844,
    "lng": -58.3815591
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "JARAMILLO 2822",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5501955,
    "lng": -58.4727706
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. CABILDO 3400",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5517992,
    "lng": -58.46622009999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVENIDA FEDERICO LACROZE 3871",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5838758,
    "lng": -58.4533332
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "FRANCISCO DE AGUIRRE 29",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.60138300000001,
    "lng": -58.4319828
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. JORGE NEWBERY 3902",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5871337,
    "lng": -58.4501515
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA FEDERICO LACROZE 4091",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5856161,
    "lng": -58.4544416
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "HUMBOLDT 1411",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.587311,
    "lng": -58.4380414
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "GODOY CRUZ 1725",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5873727,
    "lng": -58.43360990000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA FOREST 1186",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5786472,
    "lng": -58.45926219999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "MENDOZA 2468",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5614984,
    "lng": -58.458063
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "CELESTINO VIDAL 1501",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6036844,
    "lng": -58.3815591
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA FEDERICO LACROZE 2802",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5738257,
    "lng": -58.4470141
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVENIDA ÁLVAREZ THOMAS 96",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.582777,
    "lng": -58.445666
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA DE LOS CONSTITUYENTES 5991",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5721188,
    "lng": -58.5047776
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "HONDURAS 5710",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5833161,
    "lng": -58.4375902
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "ANGEL JUSTINIANO CARRANZA 1604",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5831885,
    "lng": -58.4392625
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "BONPLAND 1930",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5814943,
    "lng": -58.4353922
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "ARISTOBULO DEL VALLE 1609",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6359546,
    "lng": -58.3742082
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA BOEDO 851",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6231992,
    "lng": -58.4165479
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "DEFENSA 1088",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6205356,
    "lng": -58.3712649
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA RAFAEL OBLIGADO /Y AVDA SARMIENTO 000",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5724094,
    "lng": -58.4125504
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "CNEL NICETO VEGA 5737",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5855755,
    "lng": -58.44067829999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "HONDURAS 4733",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5903444,
    "lng": -58.42731449999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "COSTA RICA 4659",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5882677,
    "lng": -58.426698
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "COSTA RICA 4767",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5874839,
    "lng": -58.4277914
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "COSTA RICA 5654",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5823907,
    "lng": -58.4352825
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "HUMBOLDT 1445",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5870439,
    "lng": -58.43762590000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. COSTANERA RAFAEL OBLIGADO 7010",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5444068,
    "lng": -58.4323618
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. COSTANERA RAFAEL OBLIGADO 4899",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5517629,
    "lng": -58.4265663
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "FITZ ROY 1627",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5847996,
    "lng": -58.43675409999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "GORRITI 5143",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5883226,
    "lng": -58.43233949999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVENIDA CORRIENTES 3070",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6043195,
    "lng": -58.40853370000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVENIDA SAN MARTIN 3305",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6013225,
    "lng": -58.47045549999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "CASAFOUST 510",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5983962,
    "lng": -58.4542547
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVENIDA SAN MARTIN 3201",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6016863,
    "lng": -58.4690321
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA BOYACA 996",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6165206,
    "lng": -58.4639825
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA GAONA 2337",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6138426,
    "lng": -58.4600677
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA TTE. GRAL. DONATO ALVAREZ 397",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6212963,
    "lng": -58.4558982
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. GAONA 3036",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6176959,
    "lng": -58.46832980000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "FRAGATA PTE. SARMIENTO 800",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6149149,
    "lng": -58.4527418
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA ALVAREZ JONTE 4600",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6186077,
    "lng": -58.5033721
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. SEGUROLA 1493",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.622262,
    "lng": -58.49682659999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVENIDA ALICIA MOREAU DE JUSTO 192",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6005337,
    "lng": -58.36720760000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "VEDIA 3600",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.546543,
    "lng": -58.4885726
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "JUNÍN   1287",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.59345,
    "lng": -58.396791
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "SANTA CRUZ 3410",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.9823635,
    "lng": -57.5449998
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV PERALTA RAMOS 2132",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0337542,
    "lng": -57.58874849999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. FELIX U. CAMET 400",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.9771226,
    "lng": -57.54384880000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "12 DE OCTUBRE 3118",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0441178,
    "lng": -57.5449815
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. CONSTITUCION 5680",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.9624387,
    "lng": -57.5606479
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "BOULEVARD MARITIMO PATRICIO PERALTA RAMOS 2589",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0073468,
    "lng": -57.5409217
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "ALSINA 2798",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.01403639999999,
    "lng": -57.5423929
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "DIAGONAL PUEYRREDÓN 3224",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.9996204,
    "lng": -57.5537587
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "ALVARADO 2688",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.00953579999999,
    "lng": -57.55630619999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV COLON 3232",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0003349,
    "lng": -57.5567697
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "RIVADAVIA 2527",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0005271,
    "lng": -57.5468001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "GRAL ROCA 1354",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0186286,
    "lng": -57.5448028
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "RIVADAVIA 2262",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0019179,
    "lng": -57.5441579
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "MARTIN MIGUEL DE GUEMES 3279",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.018524,
    "lng": -57.54418969999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "PLAYA GRANDE (UTF 3 LOCAL 3) 1",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0277922,
    "lng": -57.53231719999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "GARAY 2270",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.010121,
    "lng": -57.5507599
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "PLAYA GRANDE BALNEARIO 4A 1",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0278901,
    "lng": -57.5311665
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "GRAL. ROCA 1331",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.018724,
    "lng": -57.5448263
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. COLON 5402",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.9892425,
    "lng": -57.578824
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "LA RIOJA 3084",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0085471,
    "lng": -57.5596908
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "LEANDRO N ALEM 3690",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0274888,
    "lng": -57.5355978
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "12 DE OCTUBRE 3524",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0414117,
    "lng": -57.5487018
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "BELGRANO 3264",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.9976948,
    "lng": -57.555023
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "9 DE JULIO 3178",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.9941406,
    "lng": -57.550777
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. MARTINEZ DE HOZ 6230",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.08906229999999,
    "lng": -57.5444245
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA PTE. A ILLIA 0",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0927379,
    "lng": -57.5529145
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "CASTELLI 1219",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0159306,
    "lng": -57.540863
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "SANTA FE 2322",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0056132,
    "lng": -57.54894849999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "ALVARADO 1251",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0165078,
    "lng": -57.5417541
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "SAN LUIS 3101",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0102167,
    "lng": -57.55719089999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "RIVADAVIA 2154",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0026646,
    "lng": -57.5431251
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA PTE. A ILLIA 0",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0927379,
    "lng": -57.5529145
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "LAS HERAS 2256",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0085625,
    "lng": -57.5414845
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "MORENO (1ER PISO) 2967",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0054771,
    "lng": -57.5426106
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "SAN MARTIN 5519",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.9844337,
    "lng": -57.57633609999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "20 DE SEPTIEMBRE 777",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.9865357,
    "lng": -57.5512636
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "EL CANO 3218",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.042776,
    "lng": -57.5450458
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "SAN MARTIN 2468",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.000195,
    "lng": -57.54563539999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "PATAGONES 660",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.9725942,
    "lng": -57.5476899
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. DE LOS TRABAJADORES 800",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0494089,
    "lng": -57.54468929999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "SANTA FE 2322",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0056132,
    "lng": -57.54894849999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "EDISON 291",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0406695,
    "lng": -57.54750779999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "BOLIVAR 4224",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.994394,
    "lng": -57.56603819999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "BERNARDO DE IRIGOYEN 3488",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0253944,
    "lng": -57.5353412
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "OLAVARRIA 3325",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.0185504,
    "lng": -57.5455479
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "ELIAS ABAD 2292",
    "localidad": "CORRIENTES",
    "provincia": "CORRIENTES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4824594,
    "lng": -58.8494998
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "CORDOBA 1461",
    "localidad": "CORRIENTES",
    "provincia": "CORRIENTES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4741125,
    "lng": -58.83505259999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "CORDOBA 1185",
    "localidad": "CORRIENTES",
    "provincia": "CORRIENTES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4709271,
    "lng": -58.8346779
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVIADOR CORREA FERNANDEZ 1923",
    "localidad": "CORRIENTES",
    "provincia": "CORRIENTES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4832196,
    "lng": -58.83105069999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA RIVADAVIA 7178",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6300762,
    "lng": -58.4664868
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "USPALLATA 2995",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.63953,
    "lng": -58.40501219999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVENIDA CABILDO 4513",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5427875,
    "lng": -58.47261640000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AVDA ALBARELLOS 2601",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5755349,
    "lng": -58.50678
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "CARHUE 12",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6392711,
    "lng": -58.5249433
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "OLIDEN 1493",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6529107,
    "lng": -58.50439979999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV RIESTRA 6051",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6795623,
    "lng": -58.47803769999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "SAN JOSE 1425",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.625105,
    "lng": -58.38600219999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV. CORDOBA 970",
    "localidad": "CIUDAD AUTONOMA DE BS AS",
    "provincia": "CAPITAL FEDERAL",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5992202,
    "lng": -58.38071530000001
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "TERMINAL DE OMNIBUS 1",
    "localidad": "NEUQUEN",
    "provincia": "NEUQUEN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.959044,
    "lng": -68.105841
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "ROCA 449",
    "localidad": "CIPOLLETTI",
    "provincia": "RIO NEGRO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.9394174,
    "lng": -67.99547489999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "RUTA 22 Y CALLE 225 225",
    "localidad": "CIPOLLETTI",
    "provincia": "RIO NEGRO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.9447465,
    "lng": -68.01481559999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "12 DE SEPTIEMBRE 840",
    "localidad": "NEUQUEN",
    "provincia": "NEUQUEN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.9563054,
    "lng": -68.0710627
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "MTRO. JOAQUIN GONZALEZ 47",
    "localidad": "NEUQUEN",
    "provincia": "NEUQUEN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.9519551,
    "lng": -68.0583042
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "MINISTRO GONZALEZ 455",
    "localidad": "NEUQUEN",
    "provincia": "NEUQUEN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.9520581,
    "lng": -68.05269969999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "GRAL. SAN MARTIN 71",
    "localidad": "CIPOLLETTI",
    "provincia": "RIO NEGRO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.9394373,
    "lng": -68.0011782
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "BV. 25 DE MAYO 10",
    "localidad": "NEUQUEN",
    "provincia": "NEUQUEN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.9519688,
    "lng": -68.0586378
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "SANTA FE 277",
    "localidad": "SAN MIGUEL DE TUCUMAN",
    "provincia": "TUCUMAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.8206298,
    "lng": -65.1986834
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "BERNARDINO RIVADAVIA 737",
    "localidad": "SAN MIGUEL DE TUCUMAN",
    "provincia": "TUCUMAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.8214424,
    "lng": -65.19921219999999
  },
  {
    "directa/distri": "Directa",
    "marca": "Budweiser",
    "domicilio": "AV ACONQUIJA 53",
    "localidad": "SAN MIGUEL DE TUCUMAN",
    "provincia": "TUCUMAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.8181676,
    "lng": -65.2658813
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "A.V LIBERTAD 202",
    "localidad": "RIO TERCERO",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.1723987,
    "lng": -64.11209269999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ALVEAR 702",
    "localidad": "RIO CUARTO",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.1229427,
    "lng": -64.35038089999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Lago Piedras Moras Local R4",
    "localidad": "HERNANDO",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.1728741,
    "lng": -64.2750263
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LIBERTAD 253",
    "localidad": "VILLA CARLOS PAZ",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.4212323,
    "lng": -64.4916836
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV O HIGGINGS 5646",
    "localidad": "CORDOBA",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.4812215,
    "lng": -64.16882030000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "CONSTITUCION 585",
    "localidad": "RIO CUARTO",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.1221474,
    "lng": -64.3489457
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LEHMANN G. BV. 178",
    "localidad": "RAFAELA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.2511145,
    "lng": -61.4910562
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "San Luis 421",
    "localidad": "CONCORDIA",
    "provincia": "Entre Rios",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.400787,
    "lng": -58.0211414
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LAS LEÑAS 1",
    "localidad": "VALLE DE LAS LEÑAS",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -35.1602778,
    "lng": -70.0669444
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SAN MARTIN Y MITRE 1",
    "localidad": "VILLAGUAY",
    "provincia": "ENTRE RIOS",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.8677008,
    "lng": -59.0269219
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "21 DE NOVIEMBRE 38",
    "localidad": "CONCEPCION DEL URUGUAY",
    "provincia": "ENTRE RIOS",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.485716,
    "lng": -58.235356
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RIVADAVIA 320 320",
    "localidad": "SAN RAFAEL",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6095287,
    "lng": -68.33126639999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "CHILE 49",
    "localidad": "RAFAELA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.2442814,
    "lng": -61.4889597
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ELFLEIN ADA MARIA   183 1",
    "localidad": "SAN CARLOS DE BARILOCHE",
    "provincia": "RIO NEGRO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -41.1360414,
    "lng": -71.3076743
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "NICANOR ALVAREZ 214",
    "localidad": "RAFAELA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.228099,
    "lng": -61.4885883
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "INT LOINAS 789",
    "localidad": "MARCOS JUAREZ",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.7100765,
    "lng": -62.1092081
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SARMIENTO 826 Y RECONQUISTA 826",
    "localidad": "PUERTO MADRYN",
    "provincia": "CHUBUT",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -42.7757867,
    "lng": -65.0398963
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BRIGADIER LOPEZ 1309",
    "localidad": "AREQUITO",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.1451481,
    "lng": -61.4688795
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ALMAFUERTE 816",
    "localidad": "CONCEPCION DEL URUGUAY",
    "provincia": "ENTRE RIOS",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.4877231,
    "lng": -58.23350620000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "FANTI LUIS AV. 1250",
    "localidad": "RAFAELA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.2592395,
    "lng": -61.5107441
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BELGRANO 980",
    "localidad": "MENDOZA",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.8899727,
    "lng": -68.8502791
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. COSTANERA DE LOS PUEBLOS ORIGINARIOS 560",
    "localidad": "CONCORDIA",
    "provincia": "ENTRE RIOS",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.4001063,
    "lng": -58.0050901
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SARMIENTO Y 25 DE MAYO 12",
    "localidad": "ESPERANZA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.44785139999999,
    "lng": -60.93110160000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BELGRANO 2032",
    "localidad": "ESPERANZA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.4496816,
    "lng": -60.92930239999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LEHMANN G. BV. 699",
    "localidad": "RAFAELA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.2457535,
    "lng": -61.49006379999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SANTA FE BV. 361",
    "localidad": "RAFAELA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.2534625,
    "lng": -61.48803269999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "FUERTE ARGENTINO 821",
    "localidad": "BAHIA BLANCA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.7005456,
    "lng": -62.264836
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BROWN 162",
    "localidad": "BAHIA BLANCA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -38.7210194,
    "lng": -62.26639669999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RN226 1 P/D: 0",
    "localidad": "MAR DEL PLATA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -37.8810147,
    "lng": -57.91909810000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "HABEGGER 761",
    "localidad": "RECONQUISTA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.1464385,
    "lng": -59.64597969999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SAN JUAN 456",
    "localidad": "MENDOZA",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.8985049,
    "lng": -68.8395807
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "A. VILLANUEVA 336",
    "localidad": "MENDOZA",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.8924086,
    "lng": -68.855484
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Circ. 12",
    "localidad": "VERONICA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -35.3873557,
    "lng": -57.33841109999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ESPEJO 188",
    "localidad": "MENDOZA",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.8894335,
    "lng": -68.8415256
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. DEL LIBERTADOR 1199",
    "localidad": "EL CALAFATE",
    "provincia": "SANTA CRUZ",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -50.3382416,
    "lng": -72.26655579999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ARISTIDES VILLANUEVA 191",
    "localidad": "MENDOZA",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.892524,
    "lng": -68.85272069999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. LIBERTADOR ESQ R.CALIVAR 1",
    "localidad": "RIVADAVIA",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5103618,
    "lng": -68.5996281
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "9 DE JULIO N. 78",
    "localidad": "CHILECITO",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.165648,
    "lng": -67.49459209999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SHOPPING ESPACIO SAN JUANWALMART 1",
    "localidad": "RIVADAVIA",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.53987249999999,
    "lng": -68.5727824
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "CHILE Y SAN MARTÍN 1",
    "localidad": "SAN RAFAEL",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6160247,
    "lng": -68.3298275
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BELGRANO 866",
    "localidad": "MENDOZA",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.891002,
    "lng": -68.850601
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "JACOBACCI Y BRESCIANO 1",
    "localidad": "BALNEARIO LAS GRUTAS",
    "provincia": "RIO NEGRO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -40.8117904,
    "lng": -65.094284
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "CARRIL MAZA (ARAUCA MALL) 1",
    "localidad": "MAIPU",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.9828848,
    "lng": -68.797673
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. LIBERTADOR ESQ TUCUMAN 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5340572,
    "lng": -68.5232351
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV.ALEM Y MAYAS 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.3942875,
    "lng": -66.8470949
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "FALUCHO Y 12",
    "localidad": "CHAMICAL",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -30.361165,
    "lng": -66.3140926
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LIMA 781",
    "localidad": "SAN MARTIN",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.0717477,
    "lng": -68.47680869999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SAN MARTIN SUR 415 1",
    "localidad": "GODOY CRUZ",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.927881,
    "lng": -68.8494714
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "JUAN B. JUSTO 177",
    "localidad": "MENDOZA",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.8837624,
    "lng": -68.8513012
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ISIDORO BOUSQUET CASI ROTONDA 1",
    "localidad": "SAN MARTIN",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.095241,
    "lng": -68.4768301
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "PANAMERICANA SN 1",
    "localidad": "LUJAN DE CUYO",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.9681509,
    "lng": -68.8759582
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LIBERTADOR ESQUINA TORANZO 1592 P/D: 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5351074,
    "lng": -68.5385941
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BOULEVARD SARMIENTO 534 P/D: 1",
    "localidad": "VILLA KRAUSE",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5826588,
    "lng": -68.54227809999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SAN JUAN  AL LADO CRUZ DE 420",
    "localidad": "GENERAL ROCA",
    "provincia": "RIO NEGRO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -39.032803,
    "lng": -67.5892227
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SAN ROMAN N1198 BSHINCAL 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.4218827,
    "lng": -66.8631613
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "URQUIZA Y SAN LUIS 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5351074,
    "lng": -68.5385941
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "FURQUE ANTES DE SANTA MARIA DE ORO 1",
    "localidad": "POCITO",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.6573191,
    "lng": -68.5798848
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RIVADAVIA E IRIGOYEN 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.4137254,
    "lng": -66.8592316
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "S.N.DE BARI Y SGO DEL EST 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.4129571,
    "lng": -66.8523427
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. FACUNDO QUIROGA Y 17 DE AGOSTO BEVITA 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.3946675,
    "lng": -66.8545893
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ADOLFO E DAVILA 68",
    "localidad": "CHILECITO",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.1611279,
    "lng": -67.49620159999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV PERON ESQUINA CATAMARC 1",
    "localidad": "CHILECITO",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.1727688,
    "lng": -67.4944928
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "MONTECASEROS Y AMHEGUINO 1",
    "localidad": "SAN RAFAEL",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6096178,
    "lng": -68.349493
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV FACUNDO QUIROGA Y SAN FRANCISCO 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.4215671,
    "lng": -66.8568356
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "COLON 423",
    "localidad": "MENDOZA",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.8937941,
    "lng": -68.8460539
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SAN ISIDRO Y REP DEL LIBANO 1",
    "localidad": "RIVADAVIA",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.1889971,
    "lng": -68.4599215
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "EMBARCADERO DIQUE PUNTA NEGRA 1 P/D: 1",
    "localidad": "ZONDA",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5208732,
    "lng": -68.818195
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "25 DE MAYO 183",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.4112435,
    "lng": -66.85662359999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SAN MARTIN 1261",
    "localidad": "TUNUYAN",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.5767491,
    "lng": -69.01600239999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RUTA 40 KM 83 1",
    "localidad": "TUNUYAN",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.5828624,
    "lng": -69.0159986
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RIVADAVIA 484 ESTE 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5372636,
    "lng": -68.5328526
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. LIBERTADOR 293 OESTE 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5351074,
    "lng": -68.5385941
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RIO DE JANEIRO 1725",
    "localidad": "VILLA ALLENDE",
    "provincia": "CORDOBA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.2999506,
    "lng": -64.2767439
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "MENDOZA SUR 702",
    "localidad": "BARRIO RAWSON",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5427531,
    "lng": -68.5252239
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. FELIX DE LA COLINA 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.4395257,
    "lng": -66.8568708
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "GRAL ACHA 30 SUR 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5499468,
    "lng": -68.5142831
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "JOAQUÍN V GONZÁLEZ 415 1",
    "localidad": "GODOY CRUZ",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.9288079,
    "lng": -68.858597
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SCALABRINI ORTIZ Y CIRCUNVALACION PATIO DE COMIDA 58 P/D: 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.532306,
    "lng": -68.5518293
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ALVEAR 70",
    "localidad": "SAN MARTIN",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.082136,
    "lng": -68.4713597
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "PALMARES OPEN MALL 1",
    "localidad": "GODOY CRUZ",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.9557044,
    "lng": -68.8589034
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SALTO RAMIREZ 110 P/D: KM 6",
    "localidad": "ELDORADO",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.4009219,
    "lng": -54.635096
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "JUAN DE ECHEGARAY 480",
    "localidad": "JACHAL",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -30.25,
    "lng": -68.75
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LIBERTADOR OESTE ENTRE CABAÑA Y MEGLIOLI 4109 P/D: 1",
    "localidad": "RIVADAVIA",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5696078,
    "lng": -68.5877513
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BUENOS AIRES 60 60",
    "localidad": "SAN RAFAEL",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6128922,
    "lng": -68.3390622
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BOULOGNE SUR MER 282 282",
    "localidad": "SAN MARTIN",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.0825027,
    "lng": -68.4775221
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. SANTA ROSA Y CIRCUNVALACION 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.444527,
    "lng": -66.8746195
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "PASO DE LOS ANDES 756",
    "localidad": "MENDOZA",
    "provincia": "MENDOZA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.8908742,
    "lng": -68.8591596
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "PAULA A SARMIENTO 418 SUR 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5351074,
    "lng": -68.5385941
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "GRAL.ACHA 30 SURGALERIA LAPRIDA 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5354816,
    "lng": -68.5259238
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RASTREADOR CALIVAR 350 N FRENTE LA POSITIVA 1",
    "localidad": "RIVADAVIA",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5364213,
    "lng": -68.5926485
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "D. VELEZ SARSFIELD ESQ SAN MARTIN BCENTRO 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.4159504,
    "lng": -66.8575467
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BELGRANO N47 BCENTRO 47",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.4134538,
    "lng": -66.8564579
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SARMIENTO ESQ RIVADAVIA 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5366989,
    "lng": -68.5257769
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ADOLFO E DAVILA ESQ LIBERTAD 1",
    "localidad": "CHILECITO",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.417017,
    "lng": -66.8531723
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LATERAL DE CIRCUNV. E IG. DE LA ROZA 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5378514,
    "lng": -68.5388451
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SATURNINO SARASSA ESQ RUTA 40 P/D: 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.5521644,
    "lng": -68.5149309
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "VALLECITO DIFUNTA CORREA 1",
    "localidad": "DIFUNTA CORREA",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.734499,
    "lng": -67.9827669
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV.ALEM 103",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.4058824,
    "lng": -66.85404319999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BENJAMIN MATIENZO Y RUTA 38 BPARQUE INDUSTRIAL 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.4139663,
    "lng": -66.8218526
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ECUADOR Y CARLOS GARDEL B° MIRADOR 1",
    "localidad": "LA RIOJA",
    "provincia": "LA RIOJA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.3946675,
    "lng": -66.8545893
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "MENDOZA 529 NORTE 1",
    "localidad": "SAN JUAN",
    "provincia": "SAN JUAN",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.8894587,
    "lng": -68.8458386
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "J.D. PERON 660",
    "localidad": "TINOGASTA",
    "provincia": "CATAMARCA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -28.0654464,
    "lng": -67.566187
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ONELLI CLEMENTE  SHOPING PATAGONIA 1",
    "localidad": "SAN CARLOS DE BARILOCHE",
    "provincia": "RIO NEGRO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -41.1374161,
    "lng": -71.29809800000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "YIRIGOYEN 128 128",
    "localidad": "PUERTO MADRYN",
    "provincia": "CHUBUT",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -42.7644674,
    "lng": -65.0365398
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "QUINTANA 54",
    "localidad": "SANTA ROSA",
    "provincia": "LA PAMPA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -36.618555,
    "lng": -64.2918786
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BELGRANO 856",
    "localidad": "EX FORTIN LAVALLE",
    "provincia": "CHACO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -25.7040525,
    "lng": -60.2007245
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RUTA 234  KM 67 1",
    "localidad": "EX FORTIN LAVALLE",
    "provincia": "CHACO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -25.7040525,
    "lng": -60.2007245
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ESPANA Y BERUTI 1",
    "localidad": "SAN CARLOS DE BARILOCHE",
    "provincia": "RIO NEGRO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": 40.46366700000001,
    "lng": -3.74922
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "GENERAL OBLIGADO 632 P/D: 3560",
    "localidad": "RECONQUISTA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.1434865,
    "lng": -59.64507690000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "B. CENTRO 19 Y 14",
    "localidad": "PRESIDENCIA ROQUE SAENZ PEÑA",
    "provincia": "CHACO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.780781,
    "lng": -60.4387965
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV SAN MARTÍN 1553",
    "localidad": "ELDORADO",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.4081864,
    "lng": -54.6098301
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SARMIENTO 979",
    "localidad": "OBERA",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4860416,
    "lng": -55.1220273
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "PADRE SENA 65",
    "localidad": "RESISTENCIA",
    "provincia": "CHACO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4421819,
    "lng": -58.9977379
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "HABEGGER 840",
    "localidad": "RECONQUISTA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.14625199999999,
    "lng": -59.6466081
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ITURRASPE 686",
    "localidad": "RECONQUISTA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.1431831,
    "lng": -59.64462409999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "FRAY ANTONIO ROSSI 3250",
    "localidad": "RECONQUISTA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.1725548,
    "lng": -59.6411276
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "14 DE JULIO 1",
    "localidad": "TEMPERLEY",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.774648,
    "lng": -58.395765
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "CHASCOMUS 1214",
    "localidad": "WILDE",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7062393,
    "lng": -58.3404415
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "YRIGOYEN 2275",
    "localidad": "LANUS",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6841313,
    "lng": -58.38639130000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "MITRE 2725",
    "localidad": "AVELLANEDA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6775055,
    "lng": -58.3478817
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "YRIGOYEN 3856",
    "localidad": "LANUS",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7000611,
    "lng": -58.3917693
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "DEL VALLE IBERLUCEA 2911",
    "localidad": "LANUS",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7058965,
    "lng": -58.3932423
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "CARNOT 390",
    "localidad": "VILLA DOMINICO",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6944893,
    "lng": -58.32189769999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "YRIGOYEN 3884",
    "localidad": "LANUS",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7002273,
    "lng": -58.3918016
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "29 DE SEPTIEMBRE 1876",
    "localidad": "LANUS",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7063937,
    "lng": -58.38987929999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "General Guemes 897",
    "localidad": "LANUS",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6759947,
    "lng": -58.3684167
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "General Guemes 897",
    "localidad": "LANUS",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6759947,
    "lng": -58.3684167
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "IRIGOYEN 1400",
    "localidad": "QUILMES",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7278392,
    "lng": -58.2648528
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "GABOTO 624",
    "localidad": "QUILMES",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7242114,
    "lng": -58.26046160000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "(67) SAENZ 45",
    "localidad": "LOMAS DE ZAMORA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7585923,
    "lng": -58.3975708
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "CARLOS CASARES 1096",
    "localidad": "CASTELAR",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6487742,
    "lng": -58.6422854
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SANTA ROSA 1457",
    "localidad": "CASTELAR",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6432931,
    "lng": -58.65664439999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "STA ROSA 1037",
    "localidad": "CASTELAR",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.64715109999999,
    "lng": -58.65751950000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV.JUAN D. PERON 103",
    "localidad": "RAMOS MEJIA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6547697,
    "lng": -58.5856783
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV LURO 5681",
    "localidad": "GREGORIO DE LAFERRERE",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7457928,
    "lng": -58.590822
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "MOSCONI 2201",
    "localidad": "RAMOS MEJIA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6678877,
    "lng": -58.5535757
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV.LURO 5151",
    "localidad": "GREGORIO DE LAFERRERE",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7417005,
    "lng": -58.59637679999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "OMBU 2630",
    "localidad": "SAN JUSTO",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6707977,
    "lng": -58.56213009999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. LURO 5698",
    "localidad": "GREGORIO DE LAFERRERE",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7456908,
    "lng": -58.59035799999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "CURUPAYTI 47 P/D:",
    "localidad": "BOULOGNE",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.4980995,
    "lng": -58.5542695
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "TERRAGNO 4112",
    "localidad": "MUNRO",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5281105,
    "lng": -58.5177235
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "GALDOS 9637",
    "localidad": "PABLO PODESTA",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5726168,
    "lng": -58.61779660000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "MITRE 1084",
    "localidad": "AVELLANEDA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6639294,
    "lng": -58.36158320000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "20 DE OCTUBRE 56",
    "localidad": "LANUS",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7067119,
    "lng": -58.391098
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "GENERAL BERNARDO O'HIGGINS 1413",
    "localidad": "LANUS",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.7044145,
    "lng": -58.3832554
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "9 DE JULIO 398",
    "localidad": "AVELLANEDA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6664352,
    "lng": -58.36745639999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "MITRE 700",
    "localidad": "LANUS",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6619031,
    "lng": -58.3653284
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RAMON FRANCO 5609",
    "localidad": "WILDE",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.695621,
    "lng": -58.319726
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Calle 13 y Cantilo",
    "localidad": "CITY BELL",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.8741838,
    "lng": -58.0481708
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "496 Y CENTENARIO",
    "localidad": "MANUEL B GONNET",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.8777309,
    "lng": -58.016651
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "DIAG 3 400",
    "localidad": "CITY BELL",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.8702069,
    "lng": -58.04387199999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "10 E/ 49 Y 50 895",
    "localidad": "LA PLATA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.9202153,
    "lng": -57.95575090000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Calle 29 377",
    "localidad": "LA PLATA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.9276949,
    "lng": -57.9829063
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Calle 9 & Calle 50",
    "localidad": "LA PLATA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.9167367,
    "lng": -57.9518184
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Diagonal 3 390",
    "localidad": "CITY BELL",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.8700163,
    "lng": -58.0438926
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Calle 41 1008",
    "localidad": "LA PLATA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.9159064,
    "lng": -57.9670418
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Av 7 1278",
    "localidad": "LA PLATA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.9195642,
    "lng": -57.9430451
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Calle 51 esquina calle 5 500",
    "localidad": "LA PLATA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.9070224,
    "lng": -57.9555344
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Calle 115 esquina calle 39",
    "localidad": "LA PLATA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.8999214,
    "lng": -57.95372020000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BROWN ALMTE 301 ESQ ABEL COSTA 1",
    "localidad": "MORON",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6494068,
    "lng": -58.6141916
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. DE MAYO 2498",
    "localidad": "RAMOS MEJIA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6662134,
    "lng": -58.5605983
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ARENALES 37",
    "localidad": "HAEDO",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6544617,
    "lng": -58.600748
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ARTURO ILLIA 2347",
    "localidad": "SAN JUSTO",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6746685,
    "lng": -58.5631237
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ENTRE RÍOS 3416",
    "localidad": "SAN JUSTO",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6795518,
    "lng": -58.55565430000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ALMAFUERTE 3417 P/D: 0",
    "localidad": "SAN JUSTO",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6802173,
    "lng": -58.5564248
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "NECOCHEA 50 P/D: 0",
    "localidad": "RAMOS MEJIA",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6416956,
    "lng": -58.5670256
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "9 DE JULIO 402",
    "localidad": "MORON",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6530484,
    "lng": -58.6184427
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "belgrano 2648",
    "localidad": "corrientes",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4738545,
    "lng": -58.81914030000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "elias abad 2292",
    "localidad": "corrientes",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4824594,
    "lng": -58.8494998
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "salta 696",
    "localidad": "corrientes",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4650405,
    "lng": -58.8390758
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "lavalle 1707 esq maipu",
    "localidad": "corrientes",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4773539,
    "lng": -58.83019230000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "junin 1325",
    "localidad": "corrientes",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.467735,
    "lng": -58.834056
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "cordoba 1461",
    "localidad": "corrientes",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4741125,
    "lng": -58.83505259999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "cordoba 1185",
    "localidad": "corrientes",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4709271,
    "lng": -58.8346779
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "bolivar 799",
    "localidad": "corrientes",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4704009,
    "lng": -58.84090459999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "aviador correa fernandez 1923",
    "localidad": "corrientes",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4832196,
    "lng": -58.83105069999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "GENERAL OBLIGADO 632 P/D: 3560",
    "localidad": "RECONQUISTA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.1434865,
    "lng": -59.64507690000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RALF SINGER 288",
    "localidad": "OBERA",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4875252,
    "lng": -55.1176215
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RALF SINGER 288",
    "localidad": "OBERA",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4875252,
    "lng": -55.1176215
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "B. CENTRO 19 Y 14",
    "localidad": "PRESIDENCIA ROQUE SAENZ PEÑA",
    "provincia": "",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.780781,
    "lng": -60.4387965
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "PRINGLES",
    "localidad": "CHARATA",
    "provincia": "SANTIAGO DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.7899757,
    "lng": -64.2687159
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LIBERTAD 178",
    "localidad": "OBERA",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4863273,
    "lng": -55.1175452
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ITALIA TERMINAL DE OMNIBUS LOCAL 1",
    "localidad": "OBERA",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.5036589,
    "lng": -55.1207258
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SAN MARTIN 627",
    "localidad": "25 DE MAYO",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.3768374,
    "lng": -54.7457913
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV SAN MARTÍN 1553",
    "localidad": "ELDORADO",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.4081864,
    "lng": -54.6098301
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SARMIENTO 979",
    "localidad": "OBERA",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4860416,
    "lng": -55.1220273
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "52CHACRA 137CASA 7 M:D 1",
    "localidad": "SANTO TOME",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.6727704,
    "lng": -60.7689185
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "52CHACRA 137CASA 7 M:D 1",
    "localidad": "SANTO TOME",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.6727704,
    "lng": -60.7689185
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SARMIENTO 610",
    "localidad": "MERCEDES",
    "provincia": "BUENOS AIRES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6518545,
    "lng": -59.4305555
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV SAN MARTIN 1372",
    "localidad": "ELDORADO",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.4071073,
    "lng": -54.6122859
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SARMIENTO 1380",
    "localidad": "OBERA",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4873645,
    "lng": -55.12725889999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SARMIENTO 1202",
    "localidad": "OBERA",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4863395,
    "lng": -55.12513879999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SAN MARTIN 865",
    "localidad": "SANTO TOME",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.6651816,
    "lng": -60.7562893
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV EL LIBERTADOR 1648",
    "localidad": "MONTECARLO",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.5678871,
    "lng": -54.7650853
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BELTRAME 1350",
    "localidad": "OBERA",
    "provincia": "MISIONES",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4904003,
    "lng": -55.1044974
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "PADRE SENA 65",
    "localidad": "RESISTENCIA",
    "provincia": "CHACO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4421819,
    "lng": -58.9977379
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "HABEGGER 840",
    "localidad": "RECONQUISTA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.14625199999999,
    "lng": -59.6466081
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RUTA PROV N 1 KM 272 1",
    "localidad": "RECONQUISTA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -30.24415339999999,
    "lng": -60.58206759999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ITURRASPE 686",
    "localidad": "RECONQUISTA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.1431831,
    "lng": -59.64462409999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LAVALLE Y BOMPLAN 1",
    "localidad": "GDOR ING V VIRASORO",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -28.0531193,
    "lng": -56.0199152
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ANGEL BLANCO 870",
    "localidad": "SANTO TOME",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.6727704,
    "lng": -60.7689185
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV BELGRANO Y RTA PROV 4 1",
    "localidad": "LEANDRO N ALEM",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4442798,
    "lng": -55.5014701
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "INDEPENDENCIA 910 1",
    "localidad": "SANTO TOME",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.6727704,
    "lng": -60.7689185
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "H IRIGOYEN Y COMANDANTE ANDRES GUACURARI 1",
    "localidad": "SANTO TOME",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -28.5584505,
    "lng": -56.046281
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "IRIGOYEN 890 1",
    "localidad": "SANTO TOME",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.6666492,
    "lng": -60.76358899999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AVDA. SAN MARTIN S/N 1",
    "localidad": "GDOR ING V VIRASORO",
    "provincia": "corrientes",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -28.0543419,
    "lng": -56.0139884
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "FRAY ANTONIO ROSSI 3250",
    "localidad": "RECONQUISTA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -29.1725548,
    "lng": -59.6411276
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Liniers 340",
    "localidad": "RESISTENCIA",
    "provincia": "CHACO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4455505,
    "lng": -58.98693169999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Av. Paraguay 24",
    "localidad": "RESISTENCIA",
    "provincia": "CHACO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.4475963,
    "lng": -58.98198309999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Av. Maipú 1701",
    "localidad": "Vicente Lopez",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5218306,
    "lng": -58.4844778
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RP4 899",
    "localidad": "San Isidro",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.4798843,
    "lng": -58.52386310000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Av. Fondo de la Legua 425, Boulogne",
    "localidad": "San Isidro",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.4969344,
    "lng": -58.5465989
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "GTC, Diag. Tucumán 699, B1640 Martínez",
    "localidad": "San Isidro",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.4910699,
    "lng": -58.5099981
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Don Bosco 402",
    "localidad": "San Isidro",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.4709116,
    "lng": -58.52269740000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Juan B. Justo 99, B1643FMA Béccar",
    "localidad": "San Isidro",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.4604773,
    "lng": -58.52879619999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Sta. Rosa 1037",
    "localidad": "Moreno",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.6008227,
    "lng": -58.7569991
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Avenida de Mayo 1158 Villa Adelina",
    "localidad": "San Isidro",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5188444,
    "lng": -58.5437035
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Don Bosco 402",
    "localidad": "San Isidro",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.4709116,
    "lng": -58.52269740000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RP4 899",
    "localidad": "San Isidro",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.4798843,
    "lng": -58.52386310000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Sarratea 8706, B1655 José León Suárez",
    "localidad": "San Isidro",
    "provincia": "Buenos Aires",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -34.5131861,
    "lng": -58.573779
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ALEM 34 34",
    "localidad": "METAN",
    "provincia": "SALTA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -25.4960231,
    "lng": -64.9749319
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "20 DE FEBRERO 410 410",
    "localidad": "METAN",
    "provincia": "SALTA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.784639,
    "lng": -65.41304029999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "MARIANO BOEDO 258 M04 258",
    "localidad": "METAN",
    "provincia": "SALTA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -25.4847563,
    "lng": -64.9754423
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "24 SE SEPTIEMBRE 306",
    "localidad": "SANTIAGO DEL ESTERO",
    "provincia": "SANTIAGO DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.7903105,
    "lng": -64.2580018
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ROCA Y PELLEGRINI",
    "localidad": "SANTIAGO DEL ESTERO",
    "provincia": "SANTIAGO DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.7839065,
    "lng": -64.2573425
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV BELGRANO (S) 4069",
    "localidad": "SANTIAGO DEL ESTERO",
    "provincia": "SANTIAGO DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.8237331,
    "lng": -64.24719259999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "PARRILLADA RUTA 157 1",
    "localidad": "SAN ANTONIO DE LA PAZ",
    "provincia": "CATAMARCA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -28.9329054,
    "lng": -65.0919991
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "INDEPENDENCIA 436",
    "localidad": "SANTIAGO DEL ESTERO",
    "provincia": "SANTIAGO DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.7909034,
    "lng": -64.2557571
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BALCARCE 164 MCDO CENTRAL PUESTO 64 1",
    "localidad": "SAN SALVADOR DE JUJUY",
    "provincia": "JUJUY",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.1857864,
    "lng": -65.2994767
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AEROPUERTO H.GUZMAN 1",
    "localidad": "PERICO",
    "provincia": "JUJUY",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.3849622,
    "lng": -65.1208524
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ALVEAR NRO.635 CENTRO 1",
    "localidad": "SAN SALVADOR DE JUJUY",
    "provincia": "JUJUY",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.1839669,
    "lng": -65.3021045
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "PORTUGAL N.166B.SAN MARTIN 1",
    "localidad": "PALPALA",
    "provincia": "JUJUY",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.2584127,
    "lng": -65.2084907
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "CATALANO 11B.SAN MARTIN 1",
    "localidad": "PALPALA",
    "provincia": "JUJUY",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.2504805,
    "lng": -65.2048385
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BELGRANO NRO.952B.CENTRO 1",
    "localidad": "SAN SALVADOR DE JUJUY",
    "provincia": "JUJUY",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.1855947,
    "lng": -65.3061929
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RAMIREZ DE VELAZCO 243 1",
    "localidad": "SAN SALVADOR DE JUJUY",
    "provincia": "JUJUY",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.1863192,
    "lng": -65.3094795
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BELGRANO NRO.678CENTRO 1",
    "localidad": "SAN SALVADOR DE JUJUY",
    "provincia": "JUJUY",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.1853744,
    "lng": -65.3024078
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ALVEAR Y GRAL.PAZN.300CENTRO 1",
    "localidad": "SAN SALVADOR DE JUJUY",
    "provincia": "JUJUY",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.1827866,
    "lng": -65.3127759
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BALCARCE N 902 B CENTRO",
    "localidad": "SALTA",
    "provincia": "SALTA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.7785037,
    "lng": -65.4110372
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV SAN MARTN 2140 2140",
    "localidad": "SALTA",
    "provincia": "SALTA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.7919295,
    "lng": -65.43288330000001
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "OLAVARRIA 1471 1471",
    "localidad": "SALTA",
    "provincia": "SALTA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.8057083,
    "lng": -65.4341129
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ALTE BROWN ESQ BELGRANO 1402",
    "localidad": "SALTA",
    "provincia": "SALTA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.7862757,
    "lng": -65.4219562
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ESPAÑA 83 B CENTRO 1",
    "localidad": "SALTA",
    "provincia": "SALTA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.7891591,
    "lng": -65.4037104
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV VIRREY TOLEDO 95 95",
    "localidad": "SALTA",
    "provincia": "SALTA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.7893374,
    "lng": -65.4023069
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BALCARSE 805 LOCAL B B  CENTRO 805",
    "localidad": "SALTA",
    "provincia": "SALTA",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -24.7797169,
    "lng": -65.41157
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ITALIA Y AVELLANEDA",
    "localidad": "CONCEPCION",
    "provincia": "SANTIAGO DEL DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.4488897,
    "lng": -70.6692655
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "9 DE JULIO Y ALBERDI 1",
    "localidad": "MONTEROS",
    "provincia": "SANTIAGO DEL DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.1715903,
    "lng": -65.49653219999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. MIGUEL SN 1",
    "localidad": "TAFI DEL VALLE",
    "provincia": "SANTIAGO DEL DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.5332084,
    "lng": -70.6633227
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LEANDRO ARAOZ 15 1",
    "localidad": "MONTEROS",
    "provincia": "SANTIAGO DEL DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.16616,
    "lng": -65.4969261
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "JUAN B. ALBERDI Y 20 DE JUNIO 1300 1",
    "localidad": "AGUILARES",
    "provincia": "SANTIAGO DEL DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -28.186577,
    "lng": -63.580611
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ALVARES SUSANA TORRES DE",
    "localidad": "EL MOLLAR",
    "provincia": "SANTIAGO DEL DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -26.9387,
    "lng": -65.70705
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "COSTA HECTOR HUGO",
    "localidad": "TAFI DEL VALLE",
    "provincia": "SANTIAGO DEL DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.3920816,
    "lng": -70.6139342
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "RESING SUN SRL",
    "localidad": "TAFI DEL VALLE",
    "provincia": "SANTIAGO DEL DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.3920816,
    "lng": -70.6139342
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "ESQUENDEFE ATILIO FABIAN",
    "localidad": "SIMOCA",
    "provincia": "SANTIAGO DEL DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.2630357,
    "lng": -65.35782619999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "GUARDIA DORA",
    "localidad": "SIMOCA",
    "provincia": "SANTIAGO DEL DEL ESTERO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -27.2630357,
    "lng": -65.35782619999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BRIGADIER LOPEZ 1309",
    "localidad": "AREQUITO",
    "provincia": "AREQUITO",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.1451481,
    "lng": -61.4688795
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "LAVALLE 942",
    "localidad": "CAÑADA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -30.24415339999999,
    "lng": -60.58206759999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "SAN MARTIN 722",
    "localidad": "VENADO TUERTO",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.745978,
    "lng": -61.96555309999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "CHIAVARINI 1576",
    "localidad": "PEREZ",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -33.0010562,
    "lng": -60.7648957
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "BELGRANO 1552",
    "localidad": "CARCARAÑA",
    "provincia": "SANTA FE",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -32.8577933,
    "lng": -61.1480003
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Pellegrini560",
    "localidad": "CONCORDIA",
    "provincia": "ENTRE RIOS",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.3985026,
    "lng": -58.0171652
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "Pelllegrini 611",
    "localidad": "CONCORDIA",
    "provincia": "ENTRE RIOS",
    "promocion": "PROMO 2X1",
    "digital_coupons": "Sí",
    "lat": -31.3978113,
    "lng": -58.01673209999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "A.V LIBERTAD 202",
    "localidad": "RIO TERCERO",
    "provincia": "CORDOBA",
    "promocion": "-",
    "digital_coupons": "No",
    "lat": -32.1723987,
    "lng": -64.11209269999999
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV. JOSE DE SAN MARTIN  Y HUBE 1",
    "localidad": "EL BOLSON",
    "provincia": "RIO NEGRO",
    "promocion": "-",
    "digital_coupons": "No",
    "lat": -41.9671324,
    "lng": -71.5363206
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "AV VELEZ SARSFIELD 895",
    "localidad": "CORDOBA",
    "provincia": "CORDOBA",
    "promocion": "-",
    "digital_coupons": "No",
    "lat": -31.4258169,
    "lng": -64.1908974
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "FRUCTUOSO RIVERA 273",
    "localidad": "CORDOBA",
    "provincia": "CORDOBA",
    "promocion": "-",
    "digital_coupons": "No",
    "lat": -31.4256549,
    "lng": -64.1922082
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "MTRO. JOAQUIN GONZALEZ 47",
    "localidad": "NEUQUEN",
    "provincia": "NEUQUEN",
    "promocion": "-",
    "digital_coupons": "No",
    "lat": -38.9519551,
    "lng": -68.0583042
  },
  {
    "directa/distri": "Distri",
    "marca": "Budweiser",
    "domicilio": "GRAL. ROCA 379",
    "localidad": "CIPOLLETTI",
    "provincia": "RIO NEGRO",
    "promocion": "-",
    "digital_coupons": "No",
    "lat": -38.9391874,
    "lng": -67.9965583
  }
];


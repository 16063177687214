import React, {useState} from "react";
import styles from "../../scss/components/ElMundialEnTusManos/_Pointer.module.scss";
import icon from "../../images/ElMundialEnTusManos/budMarker.svg";
import clearIcon from "../../images/ElMundialEnTusManos/clear.svg"

export default function Pointer({ visiblePointer }) {

    const [visiblePdvInfo, setVisiblePdvInfo] = useState(false)

    const handleClick = ()=>{
        setVisiblePdvInfo(!visiblePdvInfo)
    }

    const handleClear = ()=>{
        setVisiblePdvInfo(!visiblePdvInfo)
    }

    return (
        <div className={styles.container} >
            <div className={styles.iconContainer} onClick={handleClick}>
                    <img src={icon} alt="iconbud"/>
            </div>
            {visiblePdvInfo && (
                <div className={styles.infoContainer}>
                    <div className={styles.topInfo}>
                        <h1>
                            {visiblePointer.localidad}
                        </h1>
                        <img className={styles.clearIcon} src={clearIcon} onClick={handleClear} />
                    </div>
                    <p>
                        {visiblePointer.domicilio}
                    </p>
                </div>
            )}
            
        </div>
    )
}

import React, { useEffect, useState } from "react"
import { localidades, puntosDeVenta } from "../ElMundialEnTusManos/DataPdv";
import loadable from "@loadable/component";
import styles from "../../scss/components/ElMundialEnTusManos/_Main.module.scss";
import searchIcon from "../../images/ElMundialEnTusManos/search.svg";
import budIcon from "../../images/ElMundialEnTusManos/logoBud.svg";
import clearIcon from "../../images/ElMundialEnTusManos/clear.svg";
import SliderPuntoDeVenta from "./SliderPdv";
import {greatPlaceStyle} from '../../components/ElMundialEnTusManos/mapStyle.js';
import Pointer from "./Pointer";
import Footer from '../../components/Footer'
import Geocode from "react-geocode";

const GoogleMapReact = loadable(() => import("google-map-react"));

Geocode.setApiKey('AIzaSyBARPZHLqZGB5Ws-P98HsHLoP9KxWOV13k');
Geocode.setLanguage("es");
Geocode.setRegion("ar");

export default function ElMundialEnTusManos() {

    const [searchInput, setSearchInput] = useState("");
    const [searchOptions, setSearchOptions] = useState([]);
    const [visiblePointers, setVisiblePointers] = useState(puntosDeVenta);
    const [pdvs, setPdvs] = useState([]);
    const [position, setPosition] = useState({
        lat: -34.99729,
        lng: -64.82106
    });
    const [zoom, setZoom] = useState(6);

    const handleChange = (value) => {
        setSearchInput(value)
        if(value.length >= 2){
            let filtradas =  localidades.filter((localidad)=>{
            if(localidad.nombre.toLowerCase().includes(value.toLowerCase())) return true
            })
            setSearchOptions(filtradas)
            console.log(filtradas, "estas son las filtradas")
        } else if (value.length === 1) {
            localidades.map((localidad, index) => {console.log(localidad.nombre)
            return true
            })
            setSearchOptions(localidades)
        } else {
            setSearchOptions([])
        }
    };
    console.log(searchOptions)

    const clearInput = ()=>{
        setSearchInput("");
        setSearchOptions([]);
        setPdvs([]);
        setVisiblePointers(puntosDeVenta)
        setPosition({lat: -34.99729, lng: -64.82106})
        setZoom(6)
    }

    const handleBtnsDropdown = (localidad) => {
        console.log(localidad, "LOCALIDAD")
        let pdvFiltrados = puntosDeVenta.filter((puntoDeVenta)=>{
            if(puntoDeVenta.localidad === localidad.nombre) return true
        })
        setPdvs(pdvFiltrados);
        setSearchInput(localidad.nombre);
        setSearchOptions([]);
        setVisiblePointers(pdvFiltrados);
        console.log(pdvFiltrados, "estos son los pdvFiltrados");
        setPosition(localidad.coordenadas[0])
        setZoom(7)
    };

    const defaultProps = {
        center: {
            lat: -34.99729,
            lng: -64.82106,
        },
        zoom: 8,
    };

    return (
        <>
        <div className={styles.container}>
            <div className={styles.topContainer}>
                <img className={styles.budIcon} src={budIcon}/>
                <div className={styles.textsContainer}>
                    <h1 className={styles.elMundial}>EL MUNDIAL</h1>
                    <h1 className={styles.tusManos}>EN <em>TUS </em>&nbsp;MANOS </h1>
                    <h2>
                        ¡Buscá el punto de venta adherido más cercano y disfrutá de tu promo Bud!
                    </h2>
                </div>
                <div className={styles.searchContainer}>
                    <div className={styles.search}>
                        <div className={styles.searchBar}>
                            <img className={styles.searchIcon} src={searchIcon} />
                            <input
                                type="text"
                                value={searchInput}
                                placeholder="Buscar localidad"
                                onChange={(e) => handleChange(e.target.value)}
                            />
                            <div className={styles.clearContainer}>
                                {searchInput.length ? (
                                    <img className={styles.clearIcon} src={clearIcon} onClick={clearInput}/>
                                ) : null}
                            </div>
                        </div>
                        {pdvs.length > 0 &&
                        <div className={styles.infoResults}>
                            <p>{pdvs.length} puntos de ventas encontrados</p>
                        </div>}
                    </div>
                    <div id="container" className={styles.dropdownContainer}>
                        <div className={styles.dropdownResults}>
                            {searchOptions.map((localidad, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={searchOptions.length !== index+1 ? styles.searchOptions : styles.searchOptionsLast}
                                            onClick={() => handleBtnsDropdown(localidad)}
                                            >
                                            <p>
                                                {" "}
                                                {localidad.nombre}
                                            </p>
                                        </div>
                                    )
                            })}
                        </div>
                    </div>
                </div>

            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.pdvsContainer}>

                    <SliderPuntoDeVenta
                        pdvs={pdvs}
                        setPdvs={setPdvs}
                        setZoom={setZoom}
                        setPosition={setPosition}
                        handleBtnsDropdown={handleBtnsDropdown}
                    />

                </div>

                <div className={styles.googleMapContainer}>

                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyBARPZHLqZGB5Ws-P98HsHLoP9KxWOV13k" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        style={greatPlaceStyle}
                        center={position}
                        zoom={zoom}
                    >
                        {visiblePointers.map((visiblePointer)=>{
                            return (
                                <Pointer
                                    key={visiblePointer.codPdv}
                                    lat={visiblePointer.lat}
                                    lng={visiblePointer.lng}
                                    visiblePointer={visiblePointer}
                                />
                            )
                        })}
                    </GoogleMapReact>


                </div>
            </div>
            <Footer/>
        </div>
    </>
)
}


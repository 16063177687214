import React from "react";
import styles from "../../scss/components/ElMundialEnTusManos/_Card.module.scss"

export default function CardPuntoDeVenta({ pdv, setZoom, setPosition }) {

    const handleSelectPdv = ()=>{
        setZoom(15)
        setPosition({lat:pdv.lat, lng:pdv.lng})
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.cardContainer} onClick={handleSelectPdv} >
                <h1>{pdv.domicilio}</h1>
                <p>{pdv.localidad}, {pdv.provincia}. </p>
                <p>{pdv.promocion ?? ''}</p>
            </div>
        </div>

    )
}
